import './App.css';
import Content from './content.js';


function App() {
  return (
    <>
  <Content/>
  </>
  );
}

export default App;
